<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <div class="column is-4-tablet is-12-mobile">
        <tree-select
          :placeholder="$t('site')"
          :items="sites"
          :value="selected_sites_value"
          :disabled="!!siteId"
          :selected="siteId"
          size="is-small"
          open-all
          @change="setSelectedSites"
        />
      </div>
      <div class="column is-4-tablet is-12-mobile">
        <tree-select
          :placeholder="$tc('category', 1)"
          size="is-small"
          :items="categories"
          :value="selected_categories"
          :disabled="
            ['cat1', 'cat2', 'cat3'].indexOf(selectedRankingType) != -1
          "
          @change="setSelectedCategories"
        />
      </div>
      <div class="column is-4-tablet is-12-mobile">
        <b-field
          :label="$tc('brand', 2)"
          :disabled="disabledBrands"
        >
          <b-taginput
            ref="taginput"
            v-model="selected_brands"
            size="is-small"
            :data="brands"
            :placeholder="selected_brands.length == 0 ? $t('choose') : ''"
            :disabled="disabledBrands"
            autocomplete
            multiple
            open-on-focus
            @typing="filterBrands"
          >
            <template slot-scope="props">
              {{ props.option.label }}
            </template>
            <template
              slot="selected"
              slot-scope="props"
            >
              <b-tag
                v-for="(tag, index) in props.tags"
                :key="index"
                type="is-default"
                :tabstop="false"
                :closable="!disabledBrands"
                @close="$refs.taginput.removeTag(index, $event)"
              >
                {{ tag.label }}
              </b-tag>
            </template>
          </b-taginput>
        </b-field>
      </div>
      <div class="column is-4-tablet is-12-mobile">
        <b-field
          :label="$tc('salesman', 1)"
          :disabled="disabledSalesmen"
        >
          <b-taginput
            ref="taginputSalesman"
            v-model="selected_salesmen"
            size="is-small"
            :data="salesmen"
            :disabled="disabledSalesmen"
            :placeholder="selected_salesmen.length == 0 ? $t('choose') : ''"
            autocomplete
            multiple
            open-on-focus
            @typing="filterSalesmen"
          >
            <template slot-scope="props">
              {{ `${props.option.fname} ${props.option.lname}` }}
            </template>
            <template
              slot="selected"
              slot-scope="props"
            >
              <b-tag
                v-for="(tag, index) in props.tags"
                :key="index"
                type="is-default"
                :tabstop="false"
                :closable="!disabledSalesmen"
                @close="$refs.taginputSalesman.removeTag(index, $event)"
              >
                {{ `${tag.fname} ${tag.lname}` }}
              </b-tag>
            </template>
          </b-taginput>
        </b-field>
      </div>
      <div class="column is-4-tablet is-12-mobile">
        <b-select-validation
          v-model="selected_client_type"
          :disabled="disabledClientType"
          size="is-small"
          :label="$tc('account_type', 1)"
        >
          <template>
            <option
              v-for="opt in clientTypesProp"
              :key="opt.id"
              :value="opt.id"
            >
              {{ opt.label }}
            </option>
          </template>
        </b-select-validation>
      </div>
      <div class="column is-4-tablet is-12-mobile">
        <b-select-validation
          v-model="warehouse_id"
          size="is-small"
          required
          :label="$tc('warehouse', 1)"
        >
          <template>
            <option
              v-for="opt in warehouses"
              :key="opt.id"
              :value="opt.id"
            >
              {{ opt.country.country }} - {{ opt.currency.code }}
            </option>
          </template>
        </b-select-validation>
      </div>
    </div>
  </div>
</template>

<script>
import treeSelect from "@/components/forms/elements/treeSelect";

export default {
  components: {
    treeSelect,
  },
  props: {
    originSites: {
      type: Array,
      required: true,
    },
    originCategories: {
      type: Array,
      required: true,
    },
    brandsProp: {
      type: Array,
      required: true,
    },
    clientTypesProp: {
      type: Array,
      default: () => [],
    },
    salesmenProp: {
      type: Array,
      required: true,
    },
    warehouses: {
      type: Array,
      required: true,
    },
    siteId: {
      type: Number,
      default: null,
    },
    selectedRankingType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cancelEmit: false,
      cancelEmitSalesmen: false,
      init: true,
      sites: [],
      brands: [],
      categories: [],
      salesmen: [],
      // seleccted items
      selected_brands: [],
      selected_sites_value: [],
      selected_sites: [],
      selected_tlds: [],
      selected_categories: [],
      selected_salesmen: [],
      selected_client_type: null,
      warehouse_id: this.warehouses[0].id,
    };
  },
  computed: {
    disabledSalesmen() {
      return (
        ["salesman"].indexOf(this.selectedRankingType) != -1 ||
        this.salesmenProp.length <= 1
      );
    },
    disabledBrands() {
      return (
        ["brand"].indexOf(this.selectedRankingType) != -1 ||
        this.brandsProp.length <= 1
      );
    },
    disabledClientType() {
      return (
        ["client_type"].indexOf(this.selectedRankingType) != -1 ||
        !this.clientTypesProp ||
        (this.clientTypesProp && this.clientTypesProp.length <= 1)
      );
    },
  },
  watch: {
    brandsProp: function (newVal, oldVal) {
      if (newVal.length == 1 && newVal.length != oldVal.length) {
        this.cancelEmit = true;
        this.selected_brands = [newVal[0]];
      }
    },
    selected_brands() {
      this.emitChange();
    },
    selected_sites(newVal, oldVal) {
      if (oldVal == newVal) this.cancelEmit = true;
      if (JSON.stringify(newVal) != JSON.stringify(oldVal) || newVal == []) {
        this.emitChange();
      }
    },
    selected_tlds(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.emitChange();
      }
    },
    selected_categories(newVal, oldVal) {
      if (oldVal == newVal) this.cancelEmit = true;
      if (JSON.stringify(newVal) != JSON.stringify(oldVal) || newVal == []) {
        this.emitChange();
      }
    },
    selected_salesmen(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.emitChange();
      }
    },
    selected_client_type(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.emitChange();
      }
    },
    warehouse_id(newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.emitChange();
      }
    },
  },
  created() {
    this.brands = this.brandsProp;
    this.sites = this.setSites(this.originSites);
    this.categories = this.setCategories(this.originCategories);
    this.salesmen = this.salesmenProp;
    if (this.siteId) {
      this.cancelEmit = true;
      this.selected_sites_value = [this.siteId];
    }
    if (this.salesmenProp && this.salesmenProp.length == 1) {
      this.cancelEmitSalesmen = true;
      this.selected_salesmen = this.salesmenProp;
    }
  },
  methods: {
    filterSalesmen(search) {
      this.salesmen = this.salesmenProp.filter((option) => {
        return (
          JSON.stringify(option.name)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    filterBrands(search) {
      this.brands = this.brandsProp.filter((option) => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    emitChange() {
      if (this.cancelEmit) {
        return (this.cancelEmit = false);
      }
      if (this.cancelEmitSalesmen) {
        return (this.cancelEmitSalesmen = false);
      }

      this.$emit("update", {
        brands: this.selected_brands.reduce((arr, b) => [...arr, b.id], []),
        sites: this.selected_sites,
        tlds: this.selected_tlds,
        categories: this.selected_categories,
        salesmen: this.selected_salesmen.reduce((arr, b) => [...arr, b.id], []),
        warehouse_id: this.warehouse_id,
        client_types: this.selected_client_type,
      });
    },
    setSelectedCategories(arr) {
      this.selected_categories = arr;
    },
    setSelectedSites(arr) {
      this.selected_sites = arr;
    },
      

    // setting data for compoenents props
    setSites(sites) {
      let arr = [];
      for (let i = 0; i < sites.length; i++) {
        const site = sites[i];
        let obj = {
          name: site.label,
          label: site.label,
          id: site.id,
          children: [],
        };
        for (let j = 0; j < site.tlds.length; j++) {
          const tld = site.tlds[j];
          obj.children.push({
            name: tld.country.label,
            id: `-${tld.sld_id}`,
            label: `${site.label}-${tld.country.label}`,
          });
        }
        arr.push(obj);
      }
      return arr;
    },
    setCategories(categories) {
      let arr = [];
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        if (category.active) {
          let obj = {
            name: category.label,
            label: category.label,
            id: category.id,
            children: [],
          };
          for (let j = 0; j < category.children.length; j++) {
            const subCategory = category.children[j];
            if (subCategory.active) {
              let subObj = {
                name: subCategory.label,
                id: subCategory.id,
                label: subCategory.label,
                children: [],
              };
              if (subCategory.children) {
                for (let k = 0; k < subCategory.children.length; k++) {
                  const subCategory_2 = subCategory.children[k];
                  if (subCategory_2.active) {
                    subObj.children.push({
                      name: subCategory_2.label,
                      id: subCategory_2.id,
                      label: subCategory_2.label,
                    });
                  }
                }
              }
              obj.children.push(subObj);
            }
          }
          arr.push(obj);
        }
      }

      return arr;
    },
  },
};
</script>
