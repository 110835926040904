<template>
  <Card
    v-if="$can('read', 'orders.analytics')"
    content-class="c-table"
  >
    <div class="header-container is-align-items-baseline">
      <span
        v-if="hasTitle"
        class="is-size-4"
      >{{ $t("ranking") }}</span>
      <b-select-validation
        v-else
        v-model="selected_ranking"
        :disabled="!!prodId"
        size="is-small"
        required
        :label="$t('ranking')"
        style="min-width: 150px;"
        hide-details
      >
        <template>
          <option
            v-for="opt in ranking_value"
            :key="opt.id"
            :value="opt.id"
          >
            {{
              opt.label
            }}
          </option>
        </template>
      </b-select-validation>

      <div class="is-flex">
        <span
          :class="{
            'has-text-primary': showFilter,
            'has-text-grey': !(selected_ranking && !statsLoading),
            'is-clickable': selected_ranking && !statsLoading
          }"
          @click="
            selected_ranking && !statsLoading
              ? (showFilter = !showFilter)
              : (showFilter = showFilter)
          "
        >
          <b-icon
            size="is-small"
            icon="filter"
          />
          {{ $tc("filter", 2) }}
        </span>
        <span class="has-text-grey-lighter mx-2">|</span>
        <DatesPicker @change="changeDates" />
      </div>
    </div>
    <transition
      name="filter"
      tag="div"
    >
      <Filters
        v-if="showFilter"
        class="header-container pt-0"
        :origin-sites="originSites"
        :origin-categories="originCategories"
        :brands-prop="brands"
        :client-types-prop="clientTypes"
        :salesmen-prop="salesmen"
        :site-id="siteId"
        :warehouses="warehouses"
        :selected-ranking-type="selected_ranking"
        @update="setFilter"
      />
    </transition>
    <hr class="m-0">
    <div
      v-if="hasTitle"
      class="header-container"
    >
      <b-select-validation
        v-model="selected_ranking"
        :disabled="!!prodId"
        size="is-small"
        required
        :label="$t('ranking')"
        style="min-width: 150px;"
        hide-details
      >
        <template>
          <option
            v-for="opt in ranking_value"
            :key="opt.id"
            :value="opt.id"
          >
            {{
              opt.label
            }}
          </option>
        </template>
      </b-select-validation>
      <span
        v-if="$can('read', 'orders.analytics.export')"
        class="is-clickable"
        @click="getStats(true)"
      >
        <b-icon
          icon="download"
          size="is-small"
        />
        {{ $t("export_excel") }}

      </span>
    </div>

    <b-table
      :data="stats"
      :loading="loading"
      hoverable
      mobile-cards
      :total="total"
      :row-class="
        (row, index) =>
          row.id == 'summary' ? `summary` : `bg-${row.abc.letter.toLowerCase()}`
      "
    >
      <template v-for="head in customHeaders">
        <b-table-column
          v-slot="props"
          :key="head.value"
          :field="head.value"
          :label="head.text"
          :centered="head.centered || false"
          header-class="sticky-header"
        >
          <template v-if="head.value == 'id'">
            {{ props.row.id ? props.row.id : "-" }}
          </template>
          <template v-if="head.value == 'tld'">
            {{ props.row.label }}
          </template>
          <template v-if="head.value == 'num_orders'">
            {{
              props.row.num_orders
            }}
          </template>
          <template v-if="head.value == 'gross_revenue'">
            {{
              props.row.gross_revenue
            }}
          </template>
          <template v-if="head.value == 'discount'">
            {{
              props.row.discount
            }}
          </template>
          <template v-if="head.value == 'net_revenue'">
            {{
              props.row.net_revenue
            }}
          </template>
          <template v-if="head.value == 'cashback'">
            {{
              props.row.cashback
            }}
          </template>
          <template v-if="head.value == 'purchase_price'">
            {{
              props.row.purchase_price
            }}
          </template>
          <template v-if="head.value == 'gross_margin'">
            {{
              props.row.gross_margin
            }}
          </template>
          <template v-if="head.value == 'gross_margin_ratio'">
            {{
              props.row.gross_margin_ratio
            }}
          </template>
          <template v-if="head.value == 'avg_cart'">
            {{
              props.row.avg_cart
            }}
          </template>
          <template v-if="head.value == 'quantity'">
            {{
              `${props.row.quantity.total}`
            }}
          </template>
          <template v-if="head.value == 'quantity_free'">
            {{
              `${props.row.quantity.offered}`
            }}
          </template>
          <div v-if="head.value == 'abc'">
            {{ props.row.abc.value }}
          </div>
        </b-table-column>
      </template>
      <EmptyBlock
        slot="empty"
        icon="account-supervisor"
      />
    </b-table>
    <Pagination
      :per-page="pagination.perPage"
      :per-pages="rowsPerPage"
      :total="total"
      :current-page="pagination.page"
      @update-per-page="val => (pagination.perPage = val)"
      @update-current-page="val => (pagination.page = val)"
    />
  </Card>
</template>

<script>
import DatesPicker from "@/components/forms/elements/DatesPicker";

import Filters from "./Filters";

import debounce from "debounce";
export default {
  components: {
    Filters,
    DatesPicker
  },
  props: {
    hasTitle: {
      type: Boolean,
      default: false
    },
    rowsPerPage: {
      type: Array,
      default: () => [200, 500, 1000]
    },
    total: {
      type: Number,
      default: 0
    },
    paginationObject: {
      type: Object,
      default: () => {
        return { itemsPerPage: 200 };
      }
    },
    stats: {
      type: Array,
      required: true
    },
    originalData: {
      type: Array,
      required: true
    },
    statsLoading: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    originSites: {
      type: Array,
      required: true
    },
    brands: {
      type: Array,
      required: true
    },
    clientTypes: {
      type: Array,
      default: null
    },
    salesmen: {
      type: Array,
      required: true
    },
    originCategories: {
      type: Array,
      required: true
    },
    siteId: {
      type: Number,
      default: null
    },
    prodId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      showFilter: false,
      emitPagination: true,
      emitPaginationPerPage: false,
      loading: this.statsLoading,
      init: true,
      pagination: {
        perPage: 200,
        page: 1
      },
      customHeaders: [],
      date_filter: {
        from: null,
        to: null
      },
      ranking_value: [
        {
          label: this.$tc("brand", 2),
          id: "brand"
        },
        {
          label: `${this.$tc("category", 2)} 1`,
          id: "cat1"
        },
        {
          label: `${this.$tc("category", 2)} 2`,
          id: "cat2"
        },
        {
          label: `${this.$tc("category", 2)} 3`,
          id: "cat3"
        },
        {
          label: this.$tc("product", 2),
          id: "product"
        },
        {
          label: this.$tc("sku", 1),
          id: "sku"
        },
        {
          label: this.$tc("salesman", 2),
          id: "salesman"
        },
        {
          label: this.$tc("customer", 2),
          id: "client"
        },
        {
          label: this.$tc("account_type", 2),
          id: "client_type"
        },
        {
          label: this.$tc("voucher", 2),
          id: "voucher"
        }
      ],

      selected_ranking: null,
      warehouse_id: null,
      selected_sites: [],
      selected_tlds: [],
      selected_brands: [],
      selected_salesman: [],
      selected_categories: [],
      selected_client_types: null
    };
  },
  watch: {
    warehouses(newVal) {
      if (!this.warehouse_id) this.warehouse_id = newVal[0].id;
    },
    statsLoading(newVal) {
      if (!newVal && this.selected_ranking) {
        this.showFilter = true;
      }
      this.loading = newVal;
    },
    selected_ranking() {
      this.getStats();
    },
    originalData: function(newVal) {
      this.setHeaders(newVal);
    },
    paginationObject: function(newVal) {
      this.emitPagination = false;
      this.emitPaginationPerPage = false;
      this.pagination.page = newVal.page;
    },
    "pagination.page"() {
      if (this.emitPagination) {
        this.getStats();
      } else {
        this.emitPagination = true;
      }
    },
    "pagination.perPage"() {
      if (this.emitPaginationPerPage) {
        this.getStats();
      } else {
        this.emitPaginationPerPage = true;
      }
    }
  },
  created() {
    if (this.prodId) {
      this.selected_ranking = "sku";
    }
    if (this.paginationObject.perPage) {
      this.pagination.perPage = this.paginationObject.perPage;
    }
    this.setHeaders([]);
  },
  mounted() {
    this.$bus.$on("download-stats", (perPage) => {
      this.getStats(true, perPage);
    });
  },
  destroyed() {
    this.$bus.$off("download-stats");
  },
  methods: {
    getBgClass(head, abc) {
      return head == "abc" ? `bg-${abc.letter.toLowerCase()}` : ``;
    },
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      if (!this.init) {
        this.getStats();
      } else {
        this.init = false;
      }
    },
    setFilter: debounce(function(obj) {
      this.warehouse_id = obj.warehouse_id;
      this.selected_sites = obj.sites;
      this.selected_tlds = obj.tlds;
      this.selected_brands = obj.brands;
      this.selected_salesman = obj.salesmen;
      this.selected_categories = obj.categories;
      this.selected_client_types = obj.client_types;
      this.getStats();
    }, 1000),
    setHeaders(items) {
      if (items.length == 0) {
        this.customHeaders = [...this.headers];
        return;
      }
      let customHeaders = [...this.headers];
      if (items[0] && !items[0].stats.purchase_price) {
        let index = customHeaders.findIndex(
          elt => elt.value == "purchase_price"
        );
        customHeaders.splice(index, 1);
      }
      if (items[0] && !items[0].stats.gross_margin) {
        let index = customHeaders.findIndex(elt => elt.value == "gross_margin");
        customHeaders.splice(index, 1);
      }
      if (items[0] && !items[0].stats.gross_margin_ratio) {
        let index = customHeaders.findIndex(
          elt => elt.value == "gross_margin_ratio"
        );
        customHeaders.splice(index, 1);
      }
      this.customHeaders = customHeaders;
    },
    getStats(download, perPage) {
      if (this.init) return (this.init = false);
      if (this.cancelEmit) {
        return (this.cancelEmit = false);
      }
      this.$emit(
        "getData",
        {
          brands: this.selected_brands,
          sites: this.selected_sites,
          slds: this.selected_tlds,
          salesmen: this.selected_salesman,
          categories: this.selected_categories,
          client_types: this.selected_client_types
        },
        {
          warehouse_id: this.warehouse_id,
          date_filter: this.date_filter,
          selected_ranking: this.selected_ranking,
          pagination: this.pagination
        },
        download,
        perPage
      );
    }
  }
};
</script>
